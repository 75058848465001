.pill {
  --pill-padding-block: calc(var(--padding-xs));
  --pill-padding-inline: var(--padding-s);
  --pill-color: var(--color-info-bg);
  --pill-text-color: var(--color-info-fg);
  --pill-background-color: var(--color-info-bg);
  --pill-background-bright-color: var(--color-info-bright-bg);
  --pill-border-radius: 1000px;
  --pill-border-color: transparent;
  --pill-icon-color: var(--color-info-fg);

  display: inline-flex;
  gap: 2px;
  box-sizing: border-box;
  align-items: center;

  padding-top: calc(var(--pill-padding-block) - 2px);
  padding-bottom: calc(var(--pill-padding-block) - 2px);
  padding-left: calc(var(--pill-padding-inline) - 4px);
  padding-right: calc(var(--pill-padding-inline) - 2px);

  border-radius: var(--pill-border-radius);
  color: var(--pill-text-color);
  background-color: var(--pill-background-color);
}

.pill--info {
  --pill-background-color: var(--color-info-bg);
  --pill-text-color: var(--color-info-fg);
  --pill-border-color: var(--color-info-bg);
  --pill-background-bright-color: var(--color-info-bg-bright);
  --pill-icon-color: var(--color-info-fg);
}

.pill--error {
  --pill-background-color: var(--color-error-bg);
  --pill-text-color: var(--color-error-fg);
  --pill-border-color: var(--color-error-bg);
  --pill-background-bright-color: var(--color-error-bg-bright);
}

.pill--warning {
  --pill-background-color: var(--color-warning-bg);
  --pill-text-color: var(--color-warning-fg);
  --pill-border-color: var(--color-warning-bg);
  --pill-background-bright-color: var(--color-warning-bg-bright);
}

.pill--success {
  --pill-background-color: var(--color-success-bg);
  --pill-text-color: var(--color-success-fg);
  --pill-border-color: var(--color-success-bg);
  --pill-background-bright-color: var(--color-success-bg-bright);
}

.pill--outline {
  --pill-background-color: var(--pill-background-bright-color);
  --pill-text-color: var(--pill-text-color);
  --pill-icon-color: var(--pill-border-color);
  border-style: solid;
  border-width: 1px;
  border-color: var(--pill-border-color);
  color: var(--pill-text-color);
  background-color: var(--pill-background-color);
}

.pill__icon {
  color: var(--pill-icon-color);
}

.pill--sizer {
  height: 28px;
  padding-left: var(--padding-s);
}
