.scrollable {
  overflow: scroll;
}

.scrollable--no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable--no-scrollbar::-webkit-scrollbar {
  display: none;
}
