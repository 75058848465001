@use '@virginmediao2/momentum-css/react/components/card';

.card {
  --offer-card-image-height: 162px;
  --offer-card-badge-container-max-width: 288px;
  --offer-card-cta-fg-color: #0073a8;
  --color-custom-text-fg: #333;
  --offer-card-selected-color: #00008c;
  --offer-card-focused-color: #0050ff;

  border-radius: var(--border-radius-m);
  border: solid 2px var(--color-surface-outline);
  color: var(--mo-color-80);
  position: relative;
  height: 100%;
  width: 236px;
}

@media (width >= 48em) {
  .card {
    width: 265px;
  }
}

.card:focus {
  outline: none;
}

.card:focus-visible {
  outline: none;
  border: solid 2px var(--offer-card-focused-color);
}

.card.card:focus-within {
  outline: none;
  border: solid 2px var(--offer-card-focused-color);
  box-shadow: unset;
}

.card:hover {
  border: solid 2px var(--offer-card-selected-color);
}

.card:focus {
  border: 2px solid var(--color-surface-outline);
  outline: 2px solid var(--offer-card-focused-color);
  outline-offset: 2px;
  box-sizing: border-box;
}

.card:active {
  border: solid 2px var(--offer-card-selected-color);
  box-shadow: 0 0 0 1px var(--offer-card-selected-color);
}

.card:active:focus {
  border: solid 2px var(--offer-card-selected-color);
  box-shadow: 0 0 0 1px var(--offer-card-selected-color);
}

.card__image {
  display: flex;
  position: relative;
  width: 100%;
  height: var(--offer-card-image-height);
}

.card__hr {
  border: none;
  border-top: 1px solid var(--color-surface-outline);
}

.card__vertical-product-card-subtitle {
  opacity: 0.75;
}

.card__content {
  display: flex;
  max-width: 100%;
  flex: 1;
  text-align: left;
}

.card__badges {
  flex-wrap: wrap;
  max-width: var(--offer-card-badge-container-max-width);
  overflow-wrap: break-word;
}

.card__ctas {
  flex: 1;
}

.card__voucher-button {
  cursor: pointer;
  background: #ffe6f4;
  gap: 10px;
  padding: 0px;
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 100px;
  width: 100%;
  color: var(--color-custom-text-fg);
  border: 0;
  min-width: calc(50% - var(--rail-tile-gap) - var(--rail-tile-padding) * 3);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
