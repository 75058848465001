.voucher {
  --color-custom-text-fg: #333;
}
.voucher__code-button {
  cursor: pointer;
  background-color: #f8f8f8;
  gap: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px dashed var(--color-custom-text-fg);
  width: 100%;
}

.voucher__copy-status-container {
  gap: 10px;
  padding: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.voucher__text-container {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding-top: 20px;
}

.voucher__button-container {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding-top: 20px;
}

.voucher__button-container--button {
  width: 100%;
}

.voucher__text {
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-custom-text-fg);
}
