@use '@virginmediao2/momentum-css/react/components/section';

.section--layout-full-width {
  --section-container-padding-inline: 0;
}

.section--vertical-rhythm-xs {
  --section-padding-top: var(--padding-s);
  --section-padding-bottom: var(--padding-s);
}

.section--vertical-rhythm-3xl {
  --section-padding-top: 64px;
  --section-padding-bottom: 64px;
}

.section--variant-secondary {
  background-color: var(--color-surface-bg-dim);
}

.section__container--center {
  justify-items: center;
}

.section__container--right {
  justify-items: right;
}

@media (width >= 48em) {
  .section--layout-comfy .section__content {
    grid-column: 1 / span 10;
  }
}

.section--sticky {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--zindex-sticky-bar);
}

.section--shadow {
  box-shadow: var(--elevation-level-1);
}
