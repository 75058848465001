.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--space-between-m);
  overflow: visible;
}

.product-card__volt {
  position: absolute;
  top: 24px;
  left: -10px;
  width: 70px;
}

.product-card__media {
  max-height: 115px;
  margin: 0 auto;
}

.product-card__body {
  padding-top: var(--space-between-m);
  margin-top: var(--space-between-m);
  margin-bottom: auto;
  border-top: 1px solid var(--color-surface-outline);
}
