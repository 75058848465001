@use '@virginmediao2/momentum-css/react/components/button';

.button--expand::before {
  position: absolute;
  inset: 0;
  display: block;
  cursor: pointer;
  content: '';
}

.button--icon-only {
  font-size: 0;
  display: inline-block;
  text-indent: -9999px;
  width: 48px;
}

.button--icon-only .button__icon {
  font-size: initial;
}
