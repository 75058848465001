.badge__countdown {
  --badge-background-color: #ffe6f4 !important;
  --badge-text-color: #333 !important;
  --badge-icon-color: #ff0190 !important;
}

.badge__black {
  --badge-background-color: black !important;
  --badge-text-color: white !important;
  --badge-icon-color: white !important;
}

.badge__offer {
  --badge-background-color: #ffe6f4 !important;
  --badge-text-color: #333 !important;
  --badge-icon-color: #ff0190 !important;
}

.badge__rounded {
  --badge-border-radius: 100px !important;
}
